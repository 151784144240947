import uuidV4 from 'uuid/v4';
import axios from 'axios';
import jsonp from 'jsonp';

const network = {
	namespaced: 'network',
	state: {
		requests: [],
		baseURL: 'production' == process.env.NODE_ENV ? '' : '',
		forbizCsrf: window.forbizCsrf,
		authorizationToken: window.localStorage.getItem('token'),
	},
	actions: {
		createHeader() {
			return {}
		},

		cancelRequests({commit, getters}, payload) {
			try {
				const { id, url, uuid } = payload;
				const cancelRequests = getters.requests(id, url);
				let otherRequests = null;

				if(1 < cancelRequests.length) {
					otherRequests = cancelRequests.filter(v => v.uuid != uuid);

					for(let cancelRequest of otherRequests) {
						try {
							cancelRequest.source.cancel(`request cancel ${url}`);
						}
						catch(ex) {
							console.error('ex: ', ex);
						}
						commit('deleteRequest', {url, cancel: cancelRequest});
					}
				}
			}
			catch(exx) {
				console.error('ex: ', exx);
			}
		},

		async requestJsonp(context, payload) {
			const { url, timeout } = payload;

			return new Promise((resolve, reject) => {
				try {
					jsonp(url, {
						timeout: timeout || 5000,
					}, function (error, data) {
						if(error) reject(error);

						resolve(data);
					});
				}
				catch(ex) {
					reject(ex);
				}
			});
		},

		async request({state, dispatch, commit}, payload) {
			const { id, method, url, data, header, isPreviousCancel } = payload;
			const uuid = uuidV4();
			let cancelToken = null;
			let source = null;
			let requestInfo = null;

			//
			let requestParameters = null;
			let result = null;

			try {
				if('jsonp' === method) return await dispatch('requestJsonp', payload);

				cancelToken = axios.CancelToken;
				source = cancelToken.source();
				requestInfo = {
					url,
					id: id || "any",
					cancel: {
						uuid, source
					}
				};
				commit('setRequest', requestInfo);

				if(isPreviousCancel) {
					await dispatch('cancelRequests', {
						url,
						id: id || "any",
						uuid
					});
				}

				requestParameters = {
					method: method,
					url: `${state.baseURL}${url}`,
					headers: header ? Object.assign(await dispatch('createHeader'), header) : await dispatch('createHeader'),
					data: data ? await dispatch('convertFormData', data) : null,
					cancelToken: source.token
				};
				result = await axios(requestParameters);

				if(result.data) window.localStorage.setItem("memberName", result.data.sUserName);
				if(result && result.status && 200 == result.status) return result.data;
				else throw({ message: result.statusText });
			}
			catch(ex) {
				console.error('network exception', ex);

				const response = ex.response;

				throw({
					status: response ? response.status : ex.status,
					isCancel: axios.isCancel(ex) || null,
					message: response && response.data ? ex.response.data.message: ex.message,
				});
			}
			finally {
				commit('deleteRequest', requestInfo);
			}
		},

		async fileUpload({ dispatch }, payload) {
			const { data, url, files } = payload;
			const fileFormData = new FormData();

			for(let [key, value] of Object.entries(files)) {
				const fileList = value;

				for(let i = 0, maxCnt = fileList.length; i < maxCnt; i++) {
					fileFormData.append(key, fileList[i]);
				}
			}

			try {
				if(data) {
					for(let [key, value] of Object.entries(data)) {
						fileFormData.append(key, value);
					}
				}

				const result = await dispatch('request', {
					method: "post",
					url: url,
					data: fileFormData,
					header: {
						'Content-Type': 'multipart/form-data'
					}
				});

				return result;
			}
			catch(ex) {
				throw(ex);
			}
		},

		// data는 formData로 넘겨야 함...
		convertFormData(context, data) {
			if(data instanceof FormData) return data;

			const formData = new FormData();

			for(let [key, value] of Object.entries(data)) {
				formData.append(key, value);
			}

			return formData;
		},
	},

	mutations: {
		setRequest(state, request) {
			try {
				const has = Object.prototype.hasOwnProperty;
				const requests = state.requests;
				if(!has.call(requests, `${request.id}-${request.url}`)) requests[`${request.id}-${request.url}`] = [];

				requests[`${request.id}-${request.url}`].push(request.cancel);

				return true;
			}
			catch(ex) {
				return false;
			}
		},

		deleteRequest(state, request) {
			try {
				const requests = state.requests;
				const index = state.requests[`${request.id}-${request.url}`].findIndex(v => v.uuid == request.cancel.uuid);

				requests[`${request.id}-${request.url}`].splice(index, 1);

				return true;
			}
			catch(ex) {
				return false;
			}			
		},

		updateApiHash(state, apiHash) {
			try {
				state.forbizCsrf.hash = apiHash;

				return true;
			}
			catch(ex) {
				return false;
			}
		}
	},
	getters: {
		requests: state => (id, url) => {
			return state.requests[`${id}-${url}`] || [];
		}
	}
}

export default network;