<template>
    <div class="fb__left-nav">
		<h2 class="fb__main__title">MY PAGE</h2>
        <h2 class="fb__left-nav__title">
            <a href="/mypage/index" title="마이페이지" class="fb__t">
               마이페이지
            </a>
        </h2>
        <div class="fb__mypage">
            <div class="fb__mypage__left">
                <div class="fb__mypage__left__info">
                     <p v-if="'Y' == member.isLogin">
                        <!-- 
							requestMyInfo에서 불러오는 값
							{{member.SS_LOGIN_NM}}님<br />
						-->

						<!-- localStorage 에서 불러옴. 마이페이지 왼쪽 메뉴가 늦게 불러지는 경우 때문에 추가 network.js 참조 -->
                        {{memberName}}님<br />
                        안녕하세요!

						<button class="btn_logout" onclick="fnLogout();">로그아웃</button>
                    </p>
					<p v-else>
						비회원 주문조회
					</p>
                </div>
                <ul class="mypage__left" v-if="'Y' == member.isLogin">
                    <li class="mypage__left__menu">
                        <h3 class="mypage__left__subtitle">나의 쇼핑내역</h3>
                        <a href="/mypage/order/orderHistory" v-bind:class="{'mypage__left--active' : pageName == 'order-history'}">
                            <span>주문배송 조회</span>
                        </a>
                        <a href="/mypage/order/orderClaim" v-bind:class="{'mypage__left--active' : pageName == 'order-claim'}">
                            <span>취소/반품 조회</span>
                        </a>
                        <a href="/mypage/order/orderSurcharge" v-bind:class="{'mypage__left--active' : pageName == 'order-surcharge'}">
                            <span>추가결제금액 조회</span>
                        </a>
                    </li>

                    <li class="mypage__left__menu">
                        <h3 class="mypage__left__subtitle">나의 혜택관리</h3>

                        <a href="/mypage/mybenefit/myPoint" v-bind:class="{'mypage__left--active' : pageName == 'my-point'}">
                            <span> 나의 포인트</span>
                        </a>
                        <a href="/mypage/myCoupon/getMyCouponMain" v-bind:class="{'mypage__left--active' : pageName == 'my-coupon'}">
                            <span>나의 쿠폰</span>
                        </a>
						<a href="https://guudmembers.casamia.co.kr/web/mypage/coupon/couponList?t=0" target="_blank">
                            <span>디지털상품권 등록</span>
                        </a>
                    </li>

                    <li class="mypage__left__menu">
                        <h3 class="mypage__left__subtitle">나의 관심내역</h3>

                        <a href="/mypage/wishlist/recentlyList" v-bind:class="{'mypage__left--active' : pageName == 'recently-list'}">
                            <span> 최근 본 내역</span>
                        </a>
                        <a href="/mypage/wishlist/productList" v-bind:class="{'mypage__left--active' : pageName == 'product-list'}">
                            <span>나의 찜 상품</span>
                        </a>
						<a href="/mypage/wishlist/brandList" v-bind:class="{'mypage__left--active' : pageName == 'brand-list'}">
                            <span id="wishBrandNews">나의 관심 브랜드</span>
                        </a>
						<a href="/mypage/wishlist/restockList" v-bind:class="{'mypage__left--active' : pageName == 'restock-list'}">
                            <span>재입고 알림</span>
                        </a>
                    </li>

                    <li class="mypage__left__menu">
                        <h3 class="mypage__left__subtitle">나의 커뮤니티</h3>

                        <a href="/mypage/community/inquiryList" v-bind:class="{'mypage__left--active' : pageName == 'inquiryList'}">
                            <span> 1:1 문의 내역</span>
                        </a>
                        <a href="/mypage/voc/vocList" v-bind:class="{'mypage__left--active' : pageName == 'vocList'}" v-if="vocToday">
                            <span> 고객의 소리</span>
                        </a>
                        <a href="/mypage/community/qnaList" v-bind:class="{'mypage__left--active' : pageName == 'qnaList'}">
                            <span>나의 상품 문의</span>
                        </a>
                        <a href="/mypage/myFeedback/getMyFeedbackMain" v-bind:class="{'mypage__left--active' : pageName == 'my-review'}">
                            <span>나의 상품 리뷰</span>
                        </a>
                    </li>

                    <li class="mypage__left__menu">
                        <h3 class="mypage__left__subtitle">회원정보관리</h3>
                        <!--
						<button @click="movePage($event, 0)">회원정보 수정</button>
						<button @click="movePage($event, 1)">비밀번호 변경</button>
						-->
						<a href="javascript:moveShinsegaePointUrl('editInfo')" v-if="'Y' == member.isSsgMem">
                            <span>회원정보 수정</span>
                        </a>
        
						<a href="javascript:moveShinsegaePointUrl('chPw')" v-if="'Y' == member.isSsgMem">
                            <span>비밀번호 변경</span>  
                        </a>     

						<a href="/biz/mypage/chgInfo?TYPE=0" v-if="'Y' != member.isSsgMem">
                            <span>회원정보 수정</span>
                        </a>
        
						<a href="/biz/mypage/chgInfo?TYPE=1" v-if="'Y' == member.WEB_ID_YN && 'Y' != member.isSsgMem ">
                            <span>비밀번호 변경</span>  
                        </a>     
                          
						<a href="/mypage/memberInfo/memberInfoDelivery" v-bind:class="{'mypage__left--active' : pageName == 'delivery-Info'}">
                            <span>배송지 관리</span>
                        </a>

						<a href="javascript:moveShinsegaePointUrl('mbrTrans')" v-if="'Y' == member.isSsgMem">
                            <span>회원 통합 전환</span>  
                        </a>     

					 	<a href="javascript:;" v-if="member.GUUDPAY_USER_KEY != '' && member.GUUDPAY_USER_KEY != null" @click="openGuudPay">
                            <span>GuudPay 관리</span>
                        </a>
                    </li>
                    
                </ul>
				<ul v-else>    
					<li class="mypage__left__menu">
						<h3 class="mypage__left__subtitle">나의 쇼핑내역</h3>
						<a href="/mypage/order/orderHistory" v-bind:class="{'mypage__left--active' : pageName == 'order-history'}">
						<span>주문조회</span>
						</a>
						<a href="/mypage/order/orderClaim" v-bind:class="{'mypage__left--active' : pageName == 'order-claim'}">
							<span>취소/반품 조회</span>
						</a>
                        <a href="/mypage/order/orderSurcharge" v-bind:class="{'mypage__left--active' : pageName == 'order-surcharge'}">
							<span>추가결제금액 조회</span>
						</a>
					</li>
				</ul>
            </div>
        </div>
		<alert-component :message="alert.message" aria-modal="알림" @close-alert="alert.isOpen = false" v-if="alert.isOpen"></alert-component>
	</div>
</template>
<script>
    export default {
        name : "mypage-left-component",

        data() {
            return {
				isLogin: "",
				memberName: window.localStorage.getItem("memberName"),

				alert: {
					isOpen: false,
					message: null,
				},
				vocToday : false
            }
        },

		props: {
            member: {
                type: Object | null,
				required: true
			},
			
            pageName: { 
                type: String,
            }   
		},  
 
		created() { 
			const eventDate = moment().format("YYYYMMDDHHmmss"); 
			 if( eventDate <= '20211116090000') {   
				this.vocToday = false;   
			}else{
				this.vocToday = true;   
			}    		
			
		}, 

		mounted() {
			this.getBrandNewsList();
		},

		watch: {
			member: {
				handler(newValue, oldValue) {
					this.memberName = window.localStorage.getItem("memberName");
				},
			},
		},

		methods: {
			async requestMoveUrl(type) {
				try {
					const response = await this.$store.dispatch("network/request", {
						method: "get",
						url: "/biz/mypage/chgInfo?TYPE="+type,
						data: { 
							TYPE: type
						}
					});

					return {
						code: 1,
						url: response.redirectURL
					};
				}
				catch(ex) {
					console.error("requestMoveUrl exception...", ex);
						
					return {
						code: -1,
						message: ex.message || "오류가 발생하였습니다."
					};
				}
			},

			async movePage(e, type) {
				const alert = this.alert;
				const result = await this.requestMoveUrl(type);

				if(1 === result.code) {
					window.location.href = result.url;
				}
				else {
					alert.message = result.message;
					alert.isOpen = true;
				}
			},
			openGuudPay() {
				// GuudPay 관리 페이지를 열기 위한 로직을 구현
				var IFWin;
				var url = '/guudPay/guudPayInfo';
				var OpenOption = 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=450,height=800,top=100,left=400,';
				window.open(url, 'IfWindow' ,OpenOption);	
			},		

			async getBrandNewsList(){ 

				if(this.member.isLogin == 'Y'){ //로그인 상태 일 경우만 호출
					//사용자가 찜한 브랜드 중에 소식이 있는 브랜드 조회
					try {
							const response = await this.$store.dispatch("network/request", {
								method: "get",
								url: "/mypage/wishlist/getBrandNewsList",
								data: { 
								}
							});

							if(response != null && response.RETURN_CODE == "000000000") {

								if(response.newsList != null && response.newsList.length > 0){
									const wishBrandNewsElement = document.getElementById('wishBrandNews');

									if (wishBrandNewsElement) {
										wishBrandNewsElement.classList.add('new');
									}
								}
							}  
						}
						catch(ex) { 
							console.error("requestMoveUrl exception...", ex);
								
							return {
								code: -1,
								message: ex.message || "오류가 발생하였습니다."
							};
						}

						
					}
			},		
		},
    }
</script>
<style lang="scss" scoped>
	.fb {
		&__mypage {
			
			&__left {

				&__info {
					height: 205px;
					padding-top:  26px;
					color: #000;
					font: {
						size: 30px;
						weight: 600;
					};
					line-height: 1.5;
					box-sizing: border-box;

					.btn_logout{
						display:block;
						margin-top:18px;
						color:#787878;
						font-size:14px;
						font-weight:600;
						border:none;
					}
				}
			}

			.mypage__left {
				
				&__menu {
					padding: 20px 0 40px;
					border-top:  1px solid #e5e5e5;

					a, button {
						display: block;
						margin-top: 10px;
						color: #787878;
						font-size: 14px;
					}
				}
				
				.new{
					position:relative;
					
					&:before{
						content:"";
						display:block;
						position:absolute;
						right:-5px;
						top:1px;
						width:4px;
						height:4px;
						background-color:#ff3e2f;
						border-radius:50%;
					}
				}

				&--active {
					color: #000 !important;
                    font-weight: 600;
				}

				&__subtitle {
					margin-bottom: 16px;
					color: #000;
					font: {
						size: 17px;
						weight: bold;
					} 
				} 
			}
		}
	}
</style>